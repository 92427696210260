/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from 'axios';
import qs from 'qs';

class ApiService {
  constructor(
    tokenKey = 'token',
    baseURL = `${import.meta.env.VITE_APP_CURABOX_API_URL}/api`,
  ) {
    this.tokenKey = tokenKey;
    this.baseURL = baseURL;
    this.instance = axios.create({ baseURL });

    this.instance.interceptors.request.use(
      (config) => {
        const token = JSON.parse(localStorage.getItem(tokenKey));
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  async get(url, params) {
    try {
      const response = await this.instance.get(url, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async post(url, data) {
    try {
      if (data) {
        data.is_professional = true;
      }
      const response = await this.instance.post(url, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async patch(url, data) {
    try {
      const response = await this.instance.patch(url, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async delete(url, data) {
    try {
      const response = await this.instance.delete(url, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async getUserData() {
    return await this.get('/user');
  }

  async download(url, patientId, fileName) {
    axios({
      url: `${this.baseURL}/download/${url}`,
      params: {
        patientId,
      },
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(this.tokenKey),
        )}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileName}-${new Date().toLocaleDateString()}.pdf`,
      );
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default ApiService;
