<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO: Remove mutated prop code after vue3 upgrade -->
<template>
  <div
    class="product-row-wrapper"
    :class="{ 'product-row--not-approved': !approved }"
    v-if="product && !product.isPG51"
  >
    <div class="product-row flex-row">
      <div class="product-row__image">
        <img
          :alt="product.name"
          :src="`${product.image}.png`"
          :srcset="`${product.image}.png 1x, ${product.image}@2x.png 2x`"
        />
        <div
          v-if="productAmount > 0 && showSelectedAmount"
          class="product-row__image__amount"
        >
          {{ productAmount }}
        </div>
      </div>

      <div class="product-row__data flex-column">
        <div class="bold">{{ product.name }}</div>
        <div class="product-row__data__glove flex-row">
          <span>{{ product.packageSize }} {{ product.unit }}</span>
          <div
            class="flex-row"
            v-if="
              product.isGlove &&
              productAmount > 0 &&
              boxProduct.gloveSize &&
              !editGloveOptions
            "
          >
            <span class="product-row__data__glove__label">Größe</span>
            <span class="bold">{{ boxProduct.gloveSize.name }}</span>
          </div>
          <div
            class="flex-row"
            v-if="
              product.isGlove &&
              productAmount > 0 &&
              boxProduct.gloveMaterial &&
              !editGloveOptions
            "
          >
            <span class="product-row__data__glove__label">Material</span>
            <span class="bold">{{ boxProduct.gloveMaterial.name }}</span>
          </div>
        </div>
      </div>

      <div v-if="!approved" class="product-row__not-approved">
        nicht genehmigt
      </div>

      <div v-else-if="showFlexAmount" class="product-row__flex-amount flex-row">
        <div
          :class="[
            'product-row__flex-amount__minus',
            'flex-row',
            { 'product-row__flex-amount--disabled': boxProduct.amount <= 0 },
          ]"
          @click="emit('remove')"
        >
          <div class="icon icon-remove-20px"></div>
        </div>
        <div class="product-row__flex-amount__amount flex-row bold">
          {{ boxProduct.amount }}
        </div>
        <div
          :class="[
            'product-row__flex-amount__plus',
            'flex-row',
            { 'product-row__flex-amount--disabled': isPlusDisabled() },
          ]"
          @click="emit('add')"
        >
          <div class="icon icon-add-20px"></div>
        </div>
      </div>
    </div>

    <div
      v-if="
        product.isGlove && editGloveOptions && store.gloveOptions && approved
      "
      :class="[
        'product-row__options',
        'flex-row',
        { 'product-row__options--visible': boxProduct.amount > 0 },
      ]"
    >
      <GloveOptions
        :patient="props.patient"
        :box-product="props.boxProduct"
        @validGloveSelection="emit('validGloveSelection', $event)"
        @gloveSelection="emit('gloveSelection', $event)"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useStore } from '@/store/index';
import GloveOptions from './GloveOptions.vue';

const store = useStore();

const emit = defineEmits('add,remove', 'validGloveSelection', 'gloveSelection');

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  boxProduct: {
    type: Object,
    default: () => ({}),
  },
  editGloveOptions: {
    default: false,
  },
  approved: {
    default: true,
  },
  showSelectedAmount: {
    default: true,
  },
  showFlexAmount: {
    default: false,
  },
  totalPrice: {
    type: Number,
  },
  patient: {
    type: Object,
  },
});
const productAmount = ref(0);

onMounted(() => {
  productAmount.value = props.boxProduct?.amount || 0;
});

function isPlusDisabled() {
  return (
    props.boxProduct.amount >= props.product.maxNo ||
    props.totalPrice >= store.maxPrice - props.product.price
  );
}

watch(
  () => props.products,
  () => store.setProducts(),
);
</script>
