<template>
  <div class="profile cb-view">
    <Breadcrumb :routeNames="['Boxes', 'Profile']" />
    <ViewHeadline />
    <div class="profile__info">
      <div
        class="profile__info__edit-link"
        @click="router.push('/profile/edit')"
      >
        <img src="../assets/icon_pencil.svg" alt="edit" />
        <span>Angaben ändern</span>
      </div>
      <span class="profile__info__name">{{
        `${store.user.first_name} ${store.user.last_name}`
      }}</span>
      <span class="profile__info__username" v-if="store.user.username">{{
        `Benutzername: ${store.user.username}`
      }}</span>
      <span
        class="profile__info__email"
        v-if="store.user.isAdmin || store.user.email"
        >{{
          `E-Mail-Adresse:
              ${store.user.email}`
        }}</span
      >
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
const router = useRouter();
const store = useStore();
</script>
