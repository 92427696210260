<template>
  <div
    v-if="isOpen"
    :class="[
      'hint',
      {
        'hint--warning': MESSAGES[messageId].type === TYPE_ERROR,
        show: isOpen,
        hide: !isOpen,
      },
    ]"
  >
    <div
      v-if="MESSAGES[messageId].type === TYPE_SUCCESS"
      class="hint__icon icon icon-support-success-30px"
    />
    <div v-else class="hint__icon icon icon-support-error-30px" />
    <div class="flex-column">
      <span class="hint__headline bold"
        >{{ MESSAGES[messageId].headline
        }}<span v-if="additionalData">: {{ additionalData }}</span></span
      >
      <span class="hint__text">{{ MESSAGES[messageId].text }}</span>
    </div>
    <div
      class="hint__close-icon icon icon-close-30px"
      @click="isOpen = false"
    />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
});

const emits = defineEmits(['update:modelValue']);

const messageId = ref(null);
const isOpen = ref(false);
const timeout = ref(null);
const additionalData = ref(null);

const TYPE_ERROR = 'error';
const TYPE_SUCCESS = 'success';
const MESSAGES = {
  loginError: {
    headline: 'Fehler bei der Anmeldung',
    text: 'Ihre Anmeldung ist fehlgeschlagen. Überprüfen Sie Ihre Angaben und versuchen Sie es erneut. Melden Sie sich gegebenenfalls bei ihrem Administrator.',
    type: TYPE_ERROR,
  },
  logout: {
    headline: 'Erfolgreich abgemeldet',
    text: 'Sie haben sich erfolgreich abgemeldet. Möchten Sie weitere Änderungen vornehmen, melden Sie sich mit Kundennummer und Passwort erneut an.',
    type: TYPE_SUCCESS,
  },
  bulkChange: {
    headline: 'Änderungen erfolgreich vorgemerkt',
    text: 'Die von Ihnen gewünschten Änderungen wurden vorgemerkt. Sind Sie mit allen Anpassungen fertig? Dann reichen Sie die Änderungswünsche gesammelt bei uns ein.',
    type: TYPE_SUCCESS,
  },
  bulkChangeError: {
    headline: 'Vormerken der Änderungen fehlgeschlagen',
    text: 'Beim Speichern der Änderungen kam es zu einem Fehler. Prüfen Sie bitte die Zusammenstellungen und versuchen Sie es gegebenenfalls erneut.',
    type: TYPE_ERROR,
  },
  submitCases: {
    headline: 'Änderungen erfolgreich eingereicht',
    text: 'Die von Ihnen vorgenommenen Änderungswünsche wurden eingereicht. Wir berücksichtigen die Änderungen bei der nächstmöglichen Lieferung und übernehmen diese für zukünftige Lieferungen.',
    type: TYPE_SUCCESS,
  },
  submitCasesError: {
    headline: 'Einreichen der Änderungen fehlgeschlagen',
    text: 'Bei mindestens einer curabox gibt es einen Konflikt. Prüfen Sie, ob bereits Änderungen bei einer curabox eingereicht wurden.',
    type: TYPE_ERROR,
  },
  reserveCase: {
    headline: 'Änderungen erfolgreich vorgemerkt',
    text: 'Die von Ihnen gewünschten Änderungen wurden vorgemerkt. Sind Sie mit allen Anpassungen fertig? Dann reichen Sie die Änderungswünsche gesammelt bei uns ein.',
    type: TYPE_SUCCESS,
  },
  reserveCaseError: {
    headline: 'Vormerken der Änderungen fehlgeschlagen',
    text: 'Beim Vormerken Ihres Änderungswunsches ist ein Fehler aufgetreten. Prüfen Sie, ob bereits Änderungen bei dieser curabox eingereicht wurden.',
    type: TYPE_ERROR,
  },
  caseUserError: {
    headline: 'Änderungen bereits durch anderen Nutzer vorgenommen',
    text: 'Bei mindestens einer Eigenschaft hat bereits ein anderer Nutzer bereits eine Änderung eingereicht.Ihre Änderung ist erst im nächsten Monat wieder möglich. Bei Fragen rufen sie uns unter: 040 - 8740 9910 an.',
    type: TYPE_ERROR,
  },
  profileEdit: {
    headline: 'Angaben erfolgreich geändert',
    type: TYPE_SUCCESS,
  },
  profileEditError: {
    headline: 'Angaben konnten nicht geändert werden',
    type: TYPE_ERROR,
  },
  addedUser: {
    headline: 'Nutzer erfolgreich angelegt',
    text: '',
    type: TYPE_SUCCESS,
  },
  addedUserError: {
    headline: 'Nutzer konnte nicht angelegt werden',
    text: '',
    type: TYPE_ERROR,
  },
  deletedUser: {
    headline: 'Nutzer erfolgreich gelöscht',
    text: '',
    type: TYPE_SUCCESS,
  },
  deletedUserError: {
    headline: 'Nutzer konnte nicht gelöscht werden',
    text: '',
    type: TYPE_ERROR,
  },
  editedUser: {
    headline: 'Angaben erfolgreich geändert',
    text: '',
    type: TYPE_SUCCESS,
  },
  editedUserError: {
    headline: 'Angaben konnten nicht geändert werden',
    text: '',
    type: TYPE_ERROR,
  },
};

function show(id, data) {
  window.clearTimeout(timeout.value);
  messageId.value = id;
  additionalData.value = data;
  isOpen.value = true;
  timeout.value = window.setTimeout(() => {
    isOpen.value = false;
    emits('update:modelValue', null);
  }, 10000);
}

watch(
  () => props.modelValue,
  () => {
    show(props.modelValue.id, props.modelValue.data);
  },
);
</script>
