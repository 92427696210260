<template>
  <div class="footer bold">
    <div class="footer__link" v-for="link in LINKS" :key="link.label">
      <a v-if="link.label === 'AGB'" @click="showLayer">AGB</a>
      <a
        v-else
        target="_blank"
        :href="link.url"
        @click="trackLinkClick(link.trackingLabel)"
        >{{ link.label }}</a
      >
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

const track = inject('track');

const emit = defineEmits(['show-layer']);

const LINKS = [
  {
    label: 'Impressum',
    url: 'https://www.pflege.de/impressum/',
    trackingLabel: 'impressum',
  },
  {
    label: 'Kontakt',
    url: 'https://www.pflege.de/kontakt/',
    trackingLabel: 'kontakt',
  },
  {
    label: 'Datenschutz',
    url: 'https://www.pflege.de/datenschutz/',
    trackingLabel: 'datenschutz',
  },
  {
    label: 'AGB',
  },
  {
    label: 'Pflichtangaben (HWG und Biozide)',
    url: 'https://www.pflege.de/hwg-pflichttexte/',
    trackingLabel: 'hwg_pflichttexte',
  },
  {
    label: 'Fragen und Antworten',
    url: 'https://www.curabox.de/faq/',
    trackingLabel: 'faq',
  },
];

function trackLinkClick(label) {
  track('link-click', `footer-${label}`);
}

function showLayer() {
  trackLinkClick('agb');
  emit('show-layer');
}
</script>
