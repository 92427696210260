<template>
  <div :class="[el, { [`${el}--small`]: small }]">
    <div :class="`${el}__tooltip`" v-html="text"></div>
    <img
      :src="`${product.image}.png`"
      :srcset="`${product.image}.png 1x, ${product.image}@2x.png 2x`"
      :alt="product.name"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const el = 'product-image';
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  small: {
    default: false,
  },
});

const text = computed(() => {
  const { name, size } = props.product;
  return `<b>${name}</b></br>${size} pro ${
    size?.includes('ml') ? 'Flasche' : 'Packung'
  }`;
});
</script>
