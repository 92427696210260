<template>
  <div class="breadcrumb">
    <div
      :class="[
        'breadcrumb__item',
        { 'breadcrumb__item--current': index === routeNames.length - 1 },
      ]"
      v-for="(routeName, index) in routeNames"
      :key="index"
    >
      <span class="breadcrumb__item__title" @click="goTo(routeName)">
        {{ getRoute(routeName).title }}
      </span>
      <div class="breadcrumb__item__separator">/</div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

defineProps({
  routeNames: {
    type: Array,
    required: true,
  },
});

function getRoute(name) {
  const currentRoute = router.options.routes.find((r) => r.name === name);
  return currentRoute;
}
function goTo(name) {
  const targetRoute = getRoute(name);
  if (targetRoute.path !== route.path) {
    const path = targetRoute.path.replace(':id', route.params.id);
    router.push(path);
  }
}
</script>
