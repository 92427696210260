<template>
  <div v-if="!isLoading" class="box-delivery box-details cb-view">
    <Breadcrumb :route-names="['Boxes', 'BoxDetails', 'BoxDelivery']" />
    <BoxHeader :patient="patient" />
    <div class="box-details__wrapper triangle">
      <BoxChangeHint :patient="patient" />
      <div class="box-delivery__options flex-row">
        <div class="box-delivery__column flex-column">
          <span class="box-delivery__column__header bolder"
            >Neues Lieferintervall wählen</span
          >
          <BaseClickBox
            v-model="interval"
            :disabled="terminationChecked"
            label="Pflegehilfsmittel"
            :options="intervalOptions"
          ></BaseClickBox>

          <span class="box-delivery__column__header bolder"
            >Lieferung pausieren</span
          >
          <div
            :class="[
              'box-delivery__pause flex-row',
              { disabled: terminationChecked },
            ]"
          >
            <BaseCheckbox
              v-model="pauseDelivery.selected"
              label="Lieferung pausieren"
            ></BaseCheckbox>
          </div>

          <span class="box-delivery__column__header bolder"
            >Neuen Empfänger der Lieferung wählen</span
          >
          <div class="box-delivery__recipient">
            <BaseClickBox
              v-model="recipientAddress['recipient']"
              :disabled="terminationChecked"
              label="Empfänger"
              :options="recipientOptions"
              @input="clearOrSetBaseInputs()"
            ></BaseClickBox>
            <span class="box-delivery__input">
              <BaseInput
                v-for="(inputField, key) in deliveryRecipientInputFields[0]
                  .recipient[recipientAddress['recipient']]"
                :key="key"
                :name="key"
                v-model="recipientAddress[key]"
                :class="`box-delivery__input__${key}`"
                :label="inputField.label"
                :has-error="inputField.hasError"
                @input="inputField.hasError = false"
              />
            </span>
            <span
              class="box-delivery__recipient__outpatient_service"
              v-if="recipientAddress['recipient'] === 'outpatient_service'"
            >
              <span
                ><b>{{ store.outpatientService.name }}</b></span
              >
              <span>{{ store.outpatientService.street_and_housenr }}</span>
              <span
                >{{ store.outpatientService.zip }},
                {{ store.outpatientService.city }}</span
              >
            </span>
          </div>
        </div>

        <div class="box-delivery__column flex-column">
          <span class="box-delivery__column__header bolder"
            >Lieferung beenden</span
          >
          <BaseCheckbox
            label="Ich möchte die Lieferung beenden."
            name="termination"
            v-model="terminationChecked"
            tracking-label="deliveryStop"
          />
          <div class="termination" :class="{ disabled: !terminationChecked }">
            <TerminationReasons
              location="professional"
              :isChecked="terminationChecked"
              class="visible"
              :reasons="terminationData"
              @setValidData="isTerminationValid = $event._value"
              @setTerminationReason="terminationReasonValues = $event"
              @deliveryButtonClick="changeDelivery"
            />
          </div>
          <BaseButton
            label="Änderungen übernehmen"
            :outline="true"
            :disabled="!isButtonEnabled()"
            @click="submitCase()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store/index';
import { getNextRegularBoxes, getObjectCopy, setErrors } from '@/mixins/mixin';
import scrollHelper from '@/helper/ScrollHelper.js';
import deliveryRecipientSchema from '@/schemas/deliveryRecipientSchema.json';

const route = useRoute();
const router = useRouter();
const store = useStore();

const apiService = inject('apiService');
const showHint = inject('showHint');
const emit = defineEmits(['cases-change', 'reserveCase', 'reserveCaseError']);

const isLoading = ref(true);
const patient = ref(null);

const deliveryRecipientInputFields = ref(deliveryRecipientSchema);
const terminationChecked = ref(false);
const intervalOptions = ref([]);
const recipientOptions = ref([]);
const terminationReasonValues = ref(null);
const isTerminationValid = ref(null);
const terminationData = ref({});
const initialData = ref({});
const interval = ref(null);
const recipientAddress = ref({});
const pauseDelivery = ref({
  selected: '0',
});

onMounted(() => {
  initialize();
  setDeliveryOptions();
});

async function initialize() {
  isLoading.value = true;
  const responsePatient = await apiService.get(`/patients/${route.params.id}`);
  patient.value = responsePatient.data;

  const terminationResponseData = await apiService.get('/termination-reasons');
  terminationData.value = terminationResponseData.data;

  initialData.value = getInitialData();
  interval.value = initialData.value.interval;
  populateRecipientInputs(initialData.value.recipientAddress);
  pauseDelivery.value = initialData.value.pauseDelivery;

  isLoading.value = false;
}

function getInitialData() {
  const data = {};
  const nextRegularBoxes = getNextRegularBoxes(patient.value);
  data.interval =
    patient.value.case?.delivery_interval?.box.slug ??
    nextRegularBoxes[0]?.delivery_interval.slug;

  data.recipientAddress =
    patient.value.case?.delivery_address ?? patient.value.delivery_address;
  data.recipientAddress.recipient =
    patient.value.case?.delivery_address?.delivery_recipient.slug ??
    patient.value.delivery_recipient.slug;

  data.pauseDelivery = {
    selected:
      patient.value.case?.delivery_paused_until || patient.value.paused
        ? '1'
        : '0',
  };

  return data;
}

function setDeliveryOptions() {
  if (store.deliveryOptions && patient.value) {
    const _options = getObjectCopy(store.deliveryOptions);

    _options.intervals = _options.intervals.filter(
      (interval) => !interval.is_interval_pg51,
    );
    _options.intervals.forEach((interval) => {
      delete Object.assign(interval, { value: interval.slug }).slug;
    });

    _options.recipients.forEach((recipient) => {
      delete Object.assign(recipient, { value: recipient.slug }).slug;
    });

    const filteredRecipients = _options.recipients.filter(
      (r) => r.value !== 'other',
    );

    intervalOptions.value = _options.intervals;
    recipientOptions.value = filteredRecipients;
  }
}

function isPauseDeliverySelected() {
  return pauseDelivery.value.selected !== '0';
}

async function submitCase() {
  const data = {
    deliveryOptions: {
      terminationReason: terminationReasonValues.value?.terminationReason,
      subReason: terminationReasonValues.value?.subReason,
      interval:
        !terminationReasonValues.value &&
        initialData.value.interval !== interval.value
          ? interval.value
          : null,
      paused: !terminationReasonValues.value
        ? pauseDelivery.value.selected
        : null,
      address:
        !terminationReasonValues.value &&
        initialData.value.recipientAddress.recipient !==
          recipientAddress.value.recipient.value
          ? recipientAddress.value
          : null,
    },
    patientId: route.params.id,
  };
  const response = await apiService.post('/cases/delivery-options', data);
  if (response.message === 'success') {
    await store.fetchReservedCasesCount();
    await router.push(`/box/${route.params.id}`);
    emit('cases-change');
    showHint({ id: 'reserveCase', data: null });
  } else {
    showHint({ id: 'reserveCaseError', data: null });
    setErrors(
      response.errors,
      deliveryRecipientInputFields.value[0].recipient[
        recipientAddress.value.recipient
      ],
    );
  }
}

function isButtonEnabled() {
  if (
    !terminationReasonValues.value &&
    !terminationReasonValues.value?.subReason.value
  ) {
    return (
      interval.value !== initialData.value.interval ||
      isValidRecipientData(
        recipientAddress.value.recipient,
        initialData.value,
      ) ||
      isPauseDeliverySelected()
    );
  }

  return isTerminationValid;
}

function changeDelivery() {
  setTimeout(() => {
    terminationReasonValues.value = null;
    scrollHelper.scrollToTop();
  }, '10');
}

function isValidRecipientData(recipient, initialData) {
  const oldAddress = initialData.recipientAddress;
  const newAddress = recipientAddress.value;
  if (recipient === 'outpatient_service') {
    return true;
  } else if (JSON.stringify(oldAddress) !== JSON.stringify(newAddress)) {
    switch (recipient) {
      case 'insured_person':
        return (
          recipientAddress.value.street_and_housenr &&
          recipientAddress.value.city &&
          recipientAddress.value.zip
        );
      case 'care_person':
        return (
          recipientAddress.value.firstname &&
          recipientAddress.value.lastname &&
          recipientAddress.value.street_and_housenr &&
          recipientAddress.value.city &&
          recipientAddress.value.zip
        );
      default:
        return false;
    }
  }
  return false;
}

function clearOrSetBaseInputs() {
  if (
    recipientAddress.value.recipient ===
    initialData.value.recipientAddress.recipient
  ) {
    populateRecipientInputs(initialData.value.recipientAddress);
  } else {
    Object.keys(recipientAddress.value).forEach((key) => {
      if (key !== 'recipient') {
        recipientAddress.value[key] = '';
      }
    });
  }
}

function populateRecipientInputs(recipientAddressData) {
  Object.keys(recipientAddressData).forEach((key) => {
    recipientAddress.value[key] = recipientAddressData[key];
  });
}

watch(
  () => store.deliveryOptions,
  () => setDeliveryOptions(),
);

watch(patient, () => setDeliveryOptions());
</script>
