<template>
  <div class="box-details cb-view">
    <Breadcrumb :routeNames="['Boxes', 'BoxDetails']" />
    <BoxHeader :patient="patient" />
    <BoxCase
      @updatePatient="getDataFromDB"
      v-if="hasReservedCase()"
      class="box-details__case"
      :patient="patient"
      :is-loading="isLoading"
    />
    <div class="box-details__wrapper" v-if="patient && store.deliveryOptions">
      <div class="box-details__headline bolder">
        Pflegehilfsmittel zum Verbrauch
      </div>
      <div class="flex-row">
        <!-- Boxzusammenstellung - Left Side -->
        <div class="column" v-if="patient.orders.length">
          <div class="column__header flex-column">
            <div class="flex-row">
              <span class="bold">Boxzusammenstellung</span>
              <IconLink
                label="bearbeiten"
                icon-name="edit"
                v-if="!hasSubmittedCase()"
                @click="$router.push(`/box/${patient.id}/edit`)"
              />
            </div>
            <!--            <div class="column__header__subline">-->
            <!--              basierend auf der letzten Lieferung im-->
            <!--              <span class="bold">{{ getLastDeliveryMonth() }}</span>-->
            <!--              {{ getLastDeliveryYear() }}-->
            <!--            </div>-->
          </div>
          <ProductRow
            class="row"
            v-for="(product, index) in store.products"
            :key="index"
            :product="product"
            :box-product="getNextRegularBoxProducts(product, patient)"
          />
        </div>
        <!-- Lieferbedingungen - Right Side -->
        <div class="column" v-if="patient.orders.length">
          <div class="column__header flex-column">
            <div class="flex-row">
              <span class="bold">Lieferbedingungen</span>
              <IconLink
                label="bearbeiten"
                icon-name="edit"
                v-if="!hasSubmittedCase()"
                @click="$router.push(`/box/${patient.id}/delivery`)"
              />
            </div>
          </div>
          <DeliveryRow
            label="Lieferintervall"
            :value="getDeliveryInterval(patient)"
          />
          <DeliveryRow
            label="Empfänger"
            :value="patient.delivery_recipient?.name"
          />
          <div class="column__header flex-column bold">nächste Lieferung</div>
          <BoxRow
            v-for="(delivery, index) in nextDeliveries"
            :key="index"
            :products="delivery.products"
            :delivery-date="delivery.date"
            :tracking-link="delivery.trackingLink"
          />
        </div>
      </div>

      <div class="box-details__headline bolder">
        wiederverwendbare Bettschutzeinlagen
      </div>
      <PG51Row :patient="patient" />

      <div class="box-details__headline bolder" v-if="isBoxHistoryVisible">
        Letzte Änderungen
      </div>
      <BoxHistoryTable
        @show="isBoxHistoryVisible = true"
        @hide="isBoxHistoryVisible = false"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { formatDate, getNextRegularBoxes } from '@/mixins/mixin';

const apiService = inject('apiService');
const route = useRoute();
const router = useRouter();
const store = useStore();

const patient = ref(null);
const isLoading = ref(true);
const intervals = ref(null);
const isBoxHistoryVisible = ref(false);
const nextDeliveries = ref([]);

onMounted(() => {
  initialize();
});

async function initialize() {
  isLoading.value = true;
  await getDataFromDB();
}

async function getDataFromDB() {
  if (!route.params.id) {
    return;
  }
  const responsePatient = await apiService.get(`/patients/${route.params.id}`);
  responsePatient.data.status === 'cancelled'
    ? await router.push('/boxes')
    : (patient.value = responsePatient.data);
  await store.fetchReservedCasesCount();
  setDeliveryIntervals();
  setNextDeliveries();

  isLoading.value = false;
}

function setDeliveryIntervals() {
  if (store.deliveryOptions) {
    const _intervals = store.deliveryOptions.intervals.filter(
      (i) => !i.is_interval_pg51,
    );
    _intervals.push({
      slug: 'paused',
      name: `pausiert bis: ${formatDate(patient.value?.delivery_paused_until)}`,
    });

    intervals.value = _intervals;
  }
}

function setNextDeliveries() {
  patient.value.orders.forEach((order) => {
    order.delivery_plannings.forEach((planning) => {
      nextDeliveries.value.push({
        date: planning.date,
        products: order.products,
        trackingLink: order.tracking_link,
      });
    });
  });

  nextDeliveries.value.sort((deliveryA, deliveryB) => {
    return deliveryA.date > deliveryB.date ? 1 : -1;
  });
}

function hasReservedCase() {
  return patient.value?.case ? patient.value.case.status : false;
}

function hasSubmittedCase() {
  if (!!patient.value?.case) {
    return patient.value.case.status === 'submitted';
  }
  return false;
}

function getNextRegularBoxProducts(product, patient) {
  const nextRegularBoxes = getNextRegularBoxes(patient);
  return nextRegularBoxes[0]?.products.find(
    (p) => p.salesforceId === product.salesforceId,
  );
}

function getDeliveryInterval(patient) {
  const nextRegularBoxes = getNextRegularBoxes(patient);
  return nextRegularBoxes[0]?.paused
    ? 'paused'
    : nextRegularBoxes[0]?.delivery_interval.name;
}

watch(
  () => store.deliveryOptions,
  () => setDeliveryIntervals(),
);
</script>
<style lang="scss">
.box-details {
  &__case + div {
    margin-top: 2rem;
  }

  &__wrapper {
    background-color: var(--white);
    width: 100%;
    padding: 73px 40px 60px;

    .column {
      width: 50%;

      & + .column {
        margin-left: 2rem;
      }

      &__header {
        font-size: 20px;
        height: 58px;
        margin-bottom: 25px;
        justify-content: flex-end;

        &:first-child {
          justify-content: space-between;
        }

        .flex-row {
          justify-content: space-between;
        }

        &__subline {
          font-size: var(--std-font-size);
        }
      }

      & > .row + .row {
        margin-top: 1rem;
      }
    }
  }

  &__headline {
    font-size: 30px;
    color: var(--primary-blue);
    margin-bottom: 30px;

    &:not(:first-child) {
      margin-top: 100px;
    }

    + .flex-row {
      align-items: flex-start;
    }
  }
}
</style>
