<template>
  <div class="cases cb-view">
    <Breadcrumb :routeNames="['Boxes', 'Cases']" />
    <ViewHeadline />
    <div v-if="patients.length && store.products.length" class="table triangle">
      <div class="header flex-row">
        <div
          v-for="(column, key) in columns"
          :key="key"
          :class="['column', `column--${key}`, 'bold']"
        >
          {{ column.label }}
        </div>
        <span class="header__hint">* alt / neu</span>
      </div>

      <div
        :class="[
          'row',
          'flex-row',
          { 'row--disabled': deletingPatientIds.includes(patient.id) },
        ]"
        v-for="patient in getPatients()"
        :key="patient.id"
        @click="router.push(`box/${patient.id}`)"
      >
        <IconLink
          label="Änderungen löschen"
          icon-name="delete"
          @click="deleteCase(patient)"
        />
        <div class="column column--info">
          <div class="column--info__name">
            <span class="bold">{{ patient.data.lastname }}, </span>
            <span class="bold">{{ patient.data.firstname }}</span>
          </div>
          <div class="column--info__data">
            <span class="">{{ formatDate(patient.data.birthdate) }}</span>
            <span class="">{{ patient.data.customer_number }}</span>
          </div>
          <BoxesPatientsMenu
            @click.stop
            :patient-id="patient.id"
            :is-open="openMenuId === patient.id"
            @open="openMenuId = patient.id"
            @close="openMenuId = null"
            label-delivery="Lieferoptionen ändern"
            label-edit="Produkte ändern"
          />
        </div>
        <CaseDataDelivery class="column column--delivery" :patient="patient" />
        <CaseDataGloves class="column column--gloves" :patient="patient" />
        <CaseDataBox class="column column--box" :patient="patient" />
      </div>
      <Pagination
        :page-count="pageCount"
        :current-page="currentPage"
        @goToPage="goToPage"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/index';
import { formatDate } from '@/mixins/mixin';

const store = useStore();
const router = useRouter();
const apiService = inject('apiService');

const patients = ref([]);
const deletingPatientIds = ref([]);
const openMenuId = ref(null);
const currentPage = ref(1);
const pageCount = ref(0);
const columns = ref({
  info: {
    label: '',
  },
  delivery: {
    label: 'Lieferoptionen',
  },
  gloves: {
    label: 'Handschuhoptionen',
  },
  box: {
    label: 'Zusammenstellung curabox',
  },
});

const CASES_NUMBER_PER_PAGE = 5;

loadPatients();

async function loadPatients() {
  const response = await apiService.get('/patients/reserved-cases');
  patients.value = response.data;
}

async function deleteCase(patient) {
  deletingPatientIds.value.push(patient.id);
  const response = await apiService.delete(`/cases/${patient.case.id}`);
  if (response.message === 'success') {
    await store.fetchReservedCasesCount();
    patients.value = patients.value.filter((p) => p.id !== patient.id);
  }
}

// TODO: This looks wonky af, needs refactoring
function getPatients() {
  const _patients = patients.value.slice(
    (currentPage.value - 1) * CASES_NUMBER_PER_PAGE,
    (currentPage.value - 1) * CASES_NUMBER_PER_PAGE + CASES_NUMBER_PER_PAGE,
  );

  if (!_patients.length) {
    currentPage.value -= 1;
  }
  getProductImages(patients);
  return patients.value;
}

function getProductImages(patients) {
  patients.value.forEach((patient) => {
    patient.case.products?.forEach((patientCase) => {
      store.products.forEach((product) => {
        if (product.salesforceId === patientCase.salesforceId) {
          patientCase.image = product.image;
        }
      });
    });
  });
}

watch(patients, async () => {
  if (!patients.value || !patients.value.length) {
    await router.push('/boxes');
  } else {
    pageCount.value = Math.ceil(patients.value.length / CASES_NUMBER_PER_PAGE);
  }
});
</script>
