import Tracking from 'shared-components/src/classes/Tracking.js';

export function userHasPermission(permission) {
  return store.state.user.permissions.includes(permission);
}

export function formatDate(date, patient = null) {
  if (!date || patient?.status === 'cancelled') {
    return '-';
  }

  const d = new Date(date);
  return d.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

export function getObjectCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function setErrors(errors, formData) {
  resetErrors(formData);
  Object.keys(errors).forEach((key) => {
    const componentKey = key.split('.').slice(-1).toString();
    formData[componentKey].hasError = true;
    if (componentKey === 'password' && formData.password_confirmation) {
      formData.password_confirmation.hasError = true;
    }
    Tracking.trackDatalayer({
      category: 'professional.curabox.de',
      event: `form-${getMappedTrackingAction(
        formData[componentKey].component,
      )}-error`,
      label: `curabox-${formData[componentKey].trackingLabel}`,
    });
  });
}

export function resetErrors(formData) {
  Object.keys(formData).forEach((key) => {
    const componentKey = key.split('.').slice(-1).toString();
    formData[componentKey].hasError = false;
  });
}

export function prefillValues(data, formData) {
  if (data) {
    Object.keys(formData).forEach((key) => {
      if (data[key]) {
        formData[key].value = data[key];
      }
    });
  }
}

export function getFormDataValues(prefilledValues, formData) {
  const values = {};
  Object.keys(formData).forEach((key) => {
    const { value } = formData[key];
    const prefilledValue = prefilledValues ? prefilledValues[key] : null;
    if (value && value !== prefilledValue) {
      values[key] = value;
    }
  });

  return values;
}

export function isFormDataEmpty(prefilledValues, formData) {
  return Object.keys(getFormDataValues(prefilledValues, formData)).length === 0;
}

export function getCaseStatus(patient) {
  if (patient.status === 'cancelled') {
    return 'Gekündigt';
  }
  if (!patient || !patient.case) {
    return '';
  }

  switch (patient.case.status) {
    case 'reserved':
      return 'Änderungen vorgemerkt';
    case 'submitted':
      return 'Änderungen eingereicht';
    default:
      return '';
  }
}

export function isBoxApprovedForPatients(patients, box) {
  return patients.every((patient) => {
    return box.products.every((product) => {
      return (
        patient.approvedProducts.pg54.includes(product.salesforceId) ||
        product.amount === 0
      );
    });
  });
}

function getMappedTrackingAction(component) {
  switch (component) {
    case 'BaseRadio':
      return 'radio';
    case 'BaseSelect':
      return 'dropdown';
    default:
      return 'input';
  }
}

export function getNextRegularBoxes(patient) {
  return patient.orders.filter((b) => b.delivery_interval.slug !== 'one_time');
}
