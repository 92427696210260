export default class ScrollHelper {

    static scrollToFirstError(className = 'invalid', offset = 120) {
        setTimeout(() => {
            const elements = document.querySelectorAll(`.${className}`);
            let top = 0;
            elements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                if(rect.top < top) {
                    top = rect.top;
                }
            });

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scroll({ top: top + scrollTop - offset, behavior: "smooth" });
        }, 200);
    }

    static scrollToTop() {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }, 50);
    }
}