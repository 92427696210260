<template>
  <div class="view-headline">
    <h1>
      <span class="icon icon-chevron-left-30px" @click="goTo()"></span>
      {{ props.headline || getHeadline() || 'Zurück' }}
    </h1>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();

const props = defineProps({
  headline: {
    type: String,
  },
  patient: {
    type: Object,
  },
});

function getHeadline() {
  let headline = '';
  router.options.routes.forEach((_route) => {
    if (_route.name === route.name) {
      headline = _route.title;
    }
    if (_route.children) {
      _route.children.forEach((r) => {
        if (r.name === route.name) {
          headline = r.title;
        }
      });
    }
  });

  return headline;
}

function goTo() {
  let _route = '/boxes';
  if (route.path.includes('edit') || route.path.includes('delivery')) {
    _route = `/box/${props.patient.id}`;
  }
  router.push(_route);
}
</script>
