<template>
  <div class="pg51-row flex-row" v-if="store.products.length">
    <div class="flex-row">
      <div class="pg51-row__image">
        <img
          class="pg51-row__image"
          src="/images/curabox/products/produkt-wiederverwbettschutzeinl-large.png"
          srcset="
            /images/curabox/products/produkt-wiederverwbettschutzeinl-large.png    1x,
            /images/curabox/products/produkt-wiederverwbettschutzeinl-large@2x.png 2x
          "
          alt="Bettschutzeinlagen"
        />
      </div>
      <div class="pg51-row__description flex-column">
        <div class="bold">Wiederverwendbare Bettschutzeinlagen</div>

        <div
          v-if="isApproved()"
          class="pg51-row__status pg51-row__status--approved flex-column"
        >
          Die Pflegekasse hat {{ `${getAmount()}` }} von 4 möglichen
          Bettschutzeinlagen genehmigt.
        </div>

        <div
          v-else-if="isExpired()"
          class="pg51-row_status pg51-row_status--approved flex-column"
        >
          Sie können nun einen neuen Antrag über bis zu 4 Bettschutzeinlagen
          stellen.
        </div>

        <div v-else-if="!isApproved()">
          Dem Pflegebedürftigen stehen 4 Bettschutzeinlagen pro Jahr zu.
        </div>
      </div>
    </div>

    <div
      class="pg51-row__status pg51-row__status--requested flex-column"
      v-if="isRequested()"
    >
      <span class="icon icon-checkmark-30px"></span>
      <div class="pg51-row__status__info">
        Ihr Antrag liegt der Pflegekasse<br />
        zur Genehmigung vor.
      </div>
    </div>

    <div class="pg51-row__status flex-column" v-else-if="!isApproved()">
      <BaseButton
        :secondary="true"
        :outline="true"
        :label="isExpired() ? 'Erneut beantragen' : 'Antrag herunterladen'"
        icon="icon-download-20px"
        @click="downloadPdf()"
      />
      <div class="pg51-row__status__info">
        ausgefüllt & unterschrieben an:
        <a href="mailto:curabox@pflege.de">curabox@pflege.de</a> schicken
      </div>
    </div>

    <div
      class="pg51-row__status pg51-row__status--approved flex-column"
      v-else-if="isApproved()"
    >
      <div class="pg51-row__status__info">
        Genehmigungsintervall: <br />bis
        <span class="bold">{{ getApprovalDate() }}</span> genehmigt
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

import { useStore } from '@/store/index';
const store = useStore();

const apiService = inject('apiService');

const props = defineProps({
  patient: {
    type: Object,
    required: true,
  },
});

function downloadPdf() {
  apiService.download(
    'PdfBedLayer',
    props.patient.id,
    'Antrag_Bettschutzeinlage',
  );
}

function isRequested() {
  return props.patient.approvedProducts.pg51?.status === 'requested';
}

function isApproved() {
  return props.patient.approvedProducts.pg51?.status === 'approved';
}

function isExpired() {
  return props.patient.approvedProducts.pg51?.expired;
}

function getApprovalDate() {
  const date = new Date(props.patient.approvedProducts.pg51?.approved_until);
  return date.toLocaleDateString('de-DE', { month: 'long', year: 'numeric' });
}

function getAmount() {
  return props.patient.approvedProducts.pg51?.approved_amount || 0;
}
</script>
