<template>
  <div :class="el">
    <button
      :class="[`${el}__button`, 'bold']"
      type="button"
      @click="toggleIsOpen()"
    >
      <span :class="[`${el}__button__icon`, 'icon', 'icon-filter-20px']"></span>
      <span
        :class="`${el}__button__counter`"
        v-if="!isOpen && activeOptionCount > 0"
        >{{ activeOptionCount }}</span
      >
      Kunden filtern
    </button>
  </div>
  <div
    :class="[
      `${el}__layer`,
      {
        [`${el}__layer--open`]: isOpen,
        [`${el}__layer--open--delayed`]: delayedOpen,
      },
    ]"
  >
    <div :class="`${el}__layer__content`">
      <span
        :class="[`${el}__layer__content__icon`, 'icon', 'icon-close-30px']"
        @click="toggleIsOpen()"
      ></span>
      <span :class="`${el}__layer__content__headline`"
        >Suche nach Eigenschaften filtern</span
      >
      <div :class="`${el}__options-wrapper`">
        <div
          :class="`${el}__option`"
          v-for="(option, key) in filterOptions"
          :key="key"
        >
          <div :class="`${el}__option__label`">{{ option.label }}</div>
          <BaseClickBox
            v-model="store.boxesTable.extendedFilterValues[key]"
            :options="option.options"
            :multi="option.multi"
            :resettable="option?.resettable"
            @input="isDisabled = false"
          ></BaseClickBox>
        </div>
      </div>
      <div :class="`${el}__layer__button-wrapper`">
        <div :class="`${el}__layer__delete-link`" @click="clearFilters()">
          <span class="icon icon-close-20px"></span>
          <span>Alle Filter löschen</span>
        </div>
        <BaseButton
          @click="filter"
          :class="`${el}__layer__button`"
          label="Filter anwenden"
          :disabled="isDisabled || !activeOptionCount"
        ></BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, reactive, ref, inject } from 'vue';
import { useStore } from '@/store/index';

const el = 'extended-filter';
const track = inject('track');
const store = useStore();

const emit = defineEmits(['filter']);

const isOpen = ref(false);
const isDisabled = ref(true);
const delayedOpen = ref(false);
const timeout = ref(null);

const filterOptions = reactive({
  gloveSizes: {
    label: 'Handschuhgröße',
    options: [],
    multi: true,
  },
  /* gloveMaterials: {
    label: 'Handschuhart',
    options: [],
    multi: true,
  },*/
  deliveryPaused: {
    label: 'Lieferung pausiert',
    options: [
      {
        name: 'Ja',
        value: 1,
      },
      {
        name: 'Nein',
        value: 0,
      },
    ],
    resettable: true,
  },
  nextBoxEditable: {
    label: 'nächste Lieferung änderbar',
    options: [
      {
        name: 'Ja',
        value: 1,
      },
    ],
    resettable: true,
  },
  caseStatuses: {
    label: 'Änderungen',
    options: [
      {
        name: 'unbearbeitet',
        value: 0,
      },
      {
        name: 'vorgemerkt',
        value: 'reserved',
      },
      {
        name: 'eingereicht',
        value: 'submitted',
      },
    ],
    multi: true,
  },
});

const activeOptionCount = computed(() => {
  let count = 0;
  Object.keys(filterOptions).forEach((key) => {
    if (
      (!filterOptions[key]?.multi &&
        store.boxesTable.extendedFilterValues[key] !== null) ||
      (filterOptions[key]?.multi &&
        store.boxesTable.extendedFilterValues[key].length)
    ) {
      count += 1;
    }
  });

  return count;
});

onMounted(() => init());

filterOptions.gloveSizes.options = computed(() => {
  return store.gloveOptions.sizes;
});

function init() {
  initCaseStatuses();
}

function initCaseStatuses() {
  if (store.caseStatuses) {
    store.caseStatuses.forEach((status) => {
      const filterOption = filterOptions.caseStatuses.options.find(
        (o) => o.value === status.name,
      );
      if (filterOption) {
        filterOption.value = status.id;
      }
    });
  }
}

function toggleIsOpen() {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    timeout.value = setTimeout(() => {
      delayedOpen.value = true;
    }, 500);
  } else {
    clearTimeout(timeout);
    delayedOpen.value = false;
  }
}

function filter() {
  store.boxesTable.extendedFilterActive = true;
  store.boxesTable.simpleFilterActive = true;
  isDisabled.value = true;
  toggleIsOpen();
  emit('filter');

  Object.keys(store.boxesTable.extendedFilterValues).forEach((key) => {
    if (store.boxesTable.extendedFilterValues[key] !== null)
      track('button-click', `filter-boxes-${key}`);
  });
}

function clearFilters() {
  store.boxesTable.simpleFilterValue = '';
  Object.keys(filterOptions).forEach((key) => {
    store.boxesTable.extendedFilterValues[key] = filterOptions[key]?.multi
      ? []
      : null;
  });

  toggleIsOpen();
  emit('filter');
}
</script>
