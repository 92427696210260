<template>
  <div class="profile-edit-form">
    <BaseInput
      v-for="(data, key) in formData"
      v-show="isFieldVisible(key)"
      @change="data.hasError = false"
      :key="key"
      :name="key"
      :label="data.label"
      v-model="data.value"
      :has-error="data.hasError"
      :type="
        ['password', 'password_confirmation'].includes(key)
          ? 'password'
          : 'text'
      "
    ></BaseInput>
    <p class="profile-edit-form__password-hint">
      Das Passwort muss mindestens 11 Zeichen lang sein und Buchstaben, Zahlen
      und Sonderzeichen enthalten.
    </p>
    <BaseButton
      label="Änderungen speichern"
      @click="submit()"
      :disabled="isFormDataEmpty(store.user, formData)"
    ></BaseButton>
  </div>
</template>

<script setup>
import { ref, watch, inject } from 'vue';
import { useStore } from '@/store';
import {
  prefillValues,
  getFormDataValues,
  isFormDataEmpty,
  setErrors,
} from '@/mixins/mixin';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const showHint = inject('showHint');
const apiService = inject('apiService');

const formData = ref({
  first_name: {
    label: 'Vorname',
    input: '',
    hasError: false,
  },
  last_name: {
    label: 'Nachname',
    input: '',
    hasError: false,
  },
  email: {
    label: 'E-Mail-Adresse',
    input: '',
    hasError: false,
  },
  username: {
    label: 'Username',
    input: '',
    hasError: false,
  },
  password: {
    label: 'neues Passwort',
    input: '',
    hasError: false,
  },
  password_confirmation: {
    label: 'neues Passwort wiederholen',
    input: '',
    hasError: false,
  },
});

prefillValues(store.user, formData.value);

function isFieldVisible(key) {
  switch (key) {
    case 'email':
      return store.user.isAdmin || store.user.email;
    default:
      return true;
  }
}

async function submit() {
  const response = await apiService.patch(
    `/users/${store.user.id}`,
    getFormDataValues(store.user, formData.value),
  );
  if (response.data) {
    store.user = response.data;
    showHint({ id: 'profileEdit', data: null });
    await router.push('/profile');
  } else {
    let hintText = '';
    if (response.errors) {
      setErrors(response.errors, formData.value);
      Object.values(response.errors).forEach((value) => {
        hintText += hintText ? ', ' : '';
        hintText += value.join(', ');
      });
    }
    showHint({ id: 'profileEditError', data: hintText });
  }
}

watch(store.user, () => {
  prefillValues(store.user);
});
</script>
