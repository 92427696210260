<template>
  <div :class="[
    'error-page',
    'flex-column',
    { 'error-page--logged-in': store.isLoggedIn },
  ]">
    <img class="error-page__image" src="@/assets/404.png" srcset="@/assets/404.png 1x, @/assets/404@2x.png 2x"
      alt="404" />
    <div class="error-page__text bolder">
      Entschuldigung,<br />die Seite konnten wir nicht finden.
    </div>
    <BaseButton label="zurück zur Übersicht" icon="icon-chevron-left-20px" @click="$router.push('/boxes')" />
  </div>
</template>

<script setup>
</script>
