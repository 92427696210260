<template>
  <div class="case-data case-data--gloves flex-row">
    <div class="case-data--gloves__option" v-if="patient.case?.glove_size">
      <div class="case-data--gloves__option__header">Größe</div>
      <div class="case-data--gloves__option__value">
        <span class="case-data--gloves__option__value--old">{{
          `${patient.glove_size?.name || null}`
        }}</span>
        <span> / </span>
        <span class="case-data--gloves__option__value--new bold">{{
          patient.case.glove_size.name
        }}</span>
      </div>
    </div>
    <div
      class="case-data--gloves__option"
      v-if="patient.case?.glove_intolerances"
    >
      <div class="case-data--gloves__option__header">Allergien</div>
      <div class="case-data--gloves__option__value">
        <!--        <span class="case-data&#45;&#45;gloves__option__value&#45;&#45;old">{{-->
        <!--          patient.glove_intolerances[0]?.name-->
        <!--        }}</span>-->
        <!--        <span> / </span>-->
        <span
          class="case-data--gloves__option__value--new bold"
          v-html="getGloveIntolerances()"
        ></span>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  patient: {
    type: Object,
    required: true,
  },
});

function getGloveIntolerances() {
  const allergies = [];
  props.patient.case?.glove_intolerances.forEach((intolerance) => {
    allergies.push(` ${intolerance.name}`);
  });
  return allergies;
}
</script>
