<template>
  <div class="settings-add-user">
    <div class="settings-add-user-wrapper">
      <BaseInput
        v-for="(data, key) in formData"
        :key="key"
        :label="data.label"
        :name="key"
        :has-error="data.hasError"
        v-model="data.value"
        :type="
          ['password', 'password_confirmation'].includes(key)
            ? 'password'
            : 'text'
        "
        @change="data.hasError = false"
      >
      </BaseInput>
      <div>
        Das Passwort muss mindestens 11 Zeichen lang sein <br />und Buchstaben,
        Zahlen und Sonderzeichen enthalten
        <BaseButton
          :label="getButtonLabel()"
          @click="submitForm()"
          :disabled="isFormDataEmpty(editUser, formData)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useStore } from '../store';
import { useRoute, useRouter } from 'vue-router';
import {
  isFormDataEmpty,
  prefillValues,
  getFormDataValues,
  setErrors,
} from '@/mixins/mixin';

const route = useRoute();
const router = useRouter();
const store = useStore();

const apiService = inject('apiService');
const showHint = inject('showHint');

const editUser = ref(null);
const formData = ref({
  first_name: {
    label: 'Vorname',
    value: '',
    hasError: false,
  },
  last_name: {
    label: 'Nachname',
    value: '',
    hasError: false,
  },
  username: {
    label: 'Username',
    value: '',
    hasError: false,
  },
  password: {
    label: 'Passwort',
    value: '',
    hasError: false,
  },
  password_confirmation: {
    label: 'Passwort wiederholen',
    value: '',
    hasError: false,
  },
});

if (route.params.id) {
  formData.value.password.label = 'neues Passwort';
  formData.value.password_confirmation.label = 'neues Passwort wiederholen';
  if (store.outpatientService.user) {
    editUser.value = store.outpatientService.user.find(
      (u) => u.id.toString() === route.params.id,
    );
    prefillValues(editUser.value, formData.value);
  }
}

function getButtonLabel() {
  return editUser.value ? 'Nutzer speichern' : 'neuen Nutzer anlegen';
}

async function submitForm() {
  const response = editUser.value
    ? await apiService.patch(
        `/users/${editUser.value.id}`,
        getFormDataValues(editUser, formData.value),
      )
    : await apiService.post(
        '/users',
        getFormDataValues(formData.value, formData.value),
      );

  if (response.message === 'success') {
    showHint({ id: editUser.value ? 'editedUser' : 'addedUser', data: null });
    await router.push('/settings');
  } else {
    let hintText = '';
    if (response.errors) {
      setErrors(response.errors, formData.value);
      Object.values(response.errors).forEach((value) => {
        hintText += hintText ? ', ' : '';
        hintText += value.join(', ');
      });
    }
    showHint({
      id: editUser.value ? 'editedUserError' : 'addedUserError',
      data: hintText,
    });
  }
}
</script>
