<template>
  <div v-if="historyEntries.length">
    <table class="box-history-table">
      <tr class="bold">
        <th
          @click="toggleSortDirection()"
          class="box-history-table__header box-history-table__header--sortable flex-row"
        >
          Einreichung der Änderung am
          <div
            v-if="sortDirection === 'asc'"
            class="icon icon-arrow-up-20px"
          ></div>
          <div v-else class="icon icon-arrow-down-20px"></div>
        </th>
        <th class="box-history-table__header">eingereicht durch</th>
        <th
          class="box-history-table__header box-history-table__header--changes"
        >
          Änderung
        </th>
      </tr>
      <tr
        class="box-history-table__row"
        v-for="(entry, index) in getHistoryEntries()"
        :key="index"
      >
        <td>{{ formatDate(entry.created_at) }}</td>
        <td>
          {{
            entry.user ? `${entry.user.first_name} ${entry.user.last_name}` : ''
          }}
        </td>
        <td>{{ entry.description }}</td>
      </tr>
    </table>

    <Pagination
      v-if="pageCount > 1"
      :current-page="currentPage"
      :page-count="pageCount"
      @goToPage="goToPage"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue';
import { formatDate } from '@/mixins/mixin';
import { useRoute } from 'vue-router';
const route = useRoute();

const apiService = inject('apiService');

const emit = defineEmits(['hide', 'show']);
defineProps({
  patient: {
    type: Array,
  },
});

const historyEntries = ref([]);
let pageCount = ref(0);
let currentPage = ref(1);
let sortDirection = ref('desc');
const ENTRIES_PER_PAGE = 5;

onMounted(() => {
  initialize();
});

async function initialize() {
  const response = await apiService.get(`/user-history-patients`, route.params);
  historyEntries.value = response.data;
  if (!historyEntries.value.length) {
    emit('hide');
  } else {
    emit('show');
    sortHistoryEntries();
  }
}
pageCount = Math.ceil(historyEntries.value.length / 5);
function getHistoryEntries() {
  return historyEntries.value.slice(
    (currentPage.value - 1) * ENTRIES_PER_PAGE,
    (currentPage.value - 1) * ENTRIES_PER_PAGE + ENTRIES_PER_PAGE,
  );
}
function goToPage(page) {
  currentPage = page;
}

function toggleSortDirection() {
  sortDirection = sortDirection.value === 'asc' ? 'desc' : 'asc';
  currentPage = 1;
  sortHistoryEntries();
}

function sortHistoryEntries() {
  historyEntries.value.sort((a, b) => {
    if (sortDirection.value === 'desc') {
      return new Date(b.created_at) - new Date(a.created_at);
    }
    return new Date(a.created_at) - new Date(b.created_at);
  });
}
</script>
