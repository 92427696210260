<template>
  <div id="curabox-professional">
    <Header v-if="store.isLoggedIn" />
    <AutoLogout v-if="store.isLoggedIn" />
    <CasesHint
      v-if="
        store.isLoggedIn &&
        !['Settings', 'Error'].includes(router.currentRoute.name)
      "
      @submit="onSubmitCases"
    />
    <Hint :modelValue="hintValue" @update:modelValue="hintValue = $event" />
    <router-view v-slot="{ Component }">
      <component
        ref="currentView"
        :is="Component"
        v-if="store.adminDataFetched"
      />
    </router-view>
    <Footer @show-layer="showLayer = true" />
    <AgbLayer :showLayer="showLayer" @close="showLayer = false" />
  </div>
</template>

<script setup>
import { inject, provide, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store/index';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import AutoLogout from '@/components/AutoLogout.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();

const apiService = inject('apiService');

const showLayer = ref(false);
const currentView = ref();
const hintValue = ref(null);

provide('showHint', (value) => {
  hintValue.value = value;
});

initialize();

onMounted(() => {
  store.fetchAdminData();
});

async function initialize() {
  store.isLoggedIn = false;
  if (localStorage.getItem('token')) {
    const user = await apiService.getUserData();
    await store.initialize(user);
  }
}

function onSubmitCases() {
  switch (route.name) {
    case 'Boxes':
      currentView.value.loadPatients();
      break;
    case 'Cases':
      router.push('/boxes');
      break;
    default:
      break;
  }
}
</script>

<style lang="scss" scoped>
#curabox-professional {
  position: relative;
}
</style>
