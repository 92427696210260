<template>
  <div class="case-data case-data--delivery">
    <div class="flex-row" v-if="patient.case.delivery_interval">
      <span class="case-data--delivery__label">Intervall</span>
      <span class="bold">{{ patient.case.delivery_interval.box.name }}</span>
    </div>
    <div
      class="flex-row"
      v-if="patient.case.termination_reason || patient.case.paused"
    >
      <span class="case-data--delivery__label">Status</span>
      <span class="bold">{{ getDeliveryStatus() }}</span>
    </div>
    <div class="flex-row" v-if="patient.case.delivery_address">
      <span class="case-data--delivery__label">Empfänger</span>
      <span class="bold">{{
        patient.case.delivery_address.delivery_recipient.name
      }}</span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  patient: {
    type: Object,
    required: true,
  },
});

function getDeliveryStatus() {
  if (props.patient.case.termination_reason) {
    return 'gekündigt';
  }
  if (props.patient.case.paused) {
    return 'pausiert';
  }
}
</script>
