<template>
  <div :class="el">
    <SimpleFilter @filter="$emit('filter')" />
    <ExtendedFilter @filter="$emit('filter')" />
  </div>
</template>

<script setup>
import SimpleFilter from './SimpleFilter.vue';
import ExtendedFilter from './ExtendedFilter.vue';

const el = 'filter';
</script>
