<template>
  <div v-if="store.reservedCasesCount > 0" class="cases-hint">
    <div class="cases-hint__text">
      Bei
      <p class="cases-hint__text__count">{{ store.reservedCasesCount }}</p>
      Kunden sind Änderungen vorgemerkt.
    </div>
    <div class="cases-hint__buttons">
      <IconLink
        v-if="showLink"
        label="Alle Änderungen"
        icon-name="edit"
        @click="goToCases()"
      />
      <BaseButton
        v-if="showSubmitButton"
        class="cases-hint__button cases-hint__submitButton"
        label="Änderungen einreichen"
        :loading="isLoading"
        @click="submitCases()"
      ></BaseButton>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store/index';

const route = useRoute();
const router = useRouter();
const store = useStore();

const emit = defineEmits(['submit']);

const apiService = inject('apiService');
const showHint = inject('showHint');

const isLoading = ref(false);

const showLink = computed(() => route.name !== 'Cases' && !isLoading.value);
const showSubmitButton = computed(() => !route.path.startsWith('/box/'));

store.fetchReservedCasesCount();

async function submitCases() {
  isLoading.value = true;
  const response = await apiService.post('/cases/submit-reserved');
  if (response.message === 'success') {
    store.reservedCasesCount = 0;
    emit('submit');
  }
  showHint({
    id: response.message === 'success' ? 'submitCases' : 'submitCasesError',
    data: null,
  });
  isLoading.value = false;
}

function goToCases() {
  router.push('/cases');
}
</script>

<style lang="scss">
@import '../styles/mixins';

.cases-hint {
  background-color: var(--primary-coral-lightest);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 40px;

  &__text {
    display: flex;
    align-items: center;

    &__count {
      @include number-hint;
    }
  }

  &__buttons {
    display: flex;

    .base-button {
      height: 50px;
      width: 240px;
      margin-left: 20px;
      --primary-button: var(--primary-coral);
    }
  }
}
</style>
