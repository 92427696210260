<template>
  <div class="box-row flex-column">
    <div class="flex-row">
      <span class="box-row__label bold">{{ getLabel() }}</span>
    </div>
    <div class="flex-row box-row__product-wrapper">
      <div class="flex-row">
        <div
          class="box-row__product flex-row"
          v-for="product in boxProducts"
          :key="product.id"
          v-show="!product.isPG51"
        >
          <ProductImage :product="product" />
          <div class="box-row__product__amount">
            {{ product.amount }}
          </div>
        </div>
      </div>
      <BaseButton
        v-if="trackingLink"
        @click="openTrackingLink()"
        :secondary="true"
        :outline="true"
        label="Lieferung verfolgen"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from '@/store';
import ProductImage from '@/components/ProductImage.vue';

const store = useStore();
const boxProducts = ref([]);

const props = defineProps({
  products: {
    type: Array,
    required: true,
  },
  deliveryDate: {
    type: String,
    required: true,
  },
  trackingLink: {
    type: String,
    required: false,
  },
});

onMounted(() => {
  getProducts();
});

function getProducts() {
  props.products.forEach((p) => {
    const storeProduct = store.products.find(
      (product) => product.salesforceId === p.salesforceId,
    );

    boxProducts.value.push({
      dId: p.id,
      isPG51: p.isPG51,
      image: storeProduct.image,
      name: storeProduct.name,
      amount: p.amount,
      size: storeProduct.size,
    });
  });
}

function getLabel() {
  const deliveryDate = new Date(props.deliveryDate);
  return `curabox ${deliveryDate.toLocaleDateString('de-DE', {
    month: 'long',
    year: 'numeric',
  })}`;
}

function openTrackingLink() {
  window.open(props.trackingLink, '_blank');
}
</script>
