<template>
  <div class="download-flyout" v-if="store.isLoggedIn">
    <div
      class="download-flyout__button"
      :class="{ 'download-flyout__button--closed': !isOpen }"
      @click="isOpen = !isOpen"
    >
      <span class="icon icon-add-30px" />
      <span class="download-flyout__button__text bold">Kunde hinzufügen</span>
    </div>
    <div class="download-flyout__content" v-if="isOpen">
      <span
        class="download-flyout__content__close-icon icon icon-close-30px"
        @click="isOpen = false"
      />
      <span class="download-flyout__content__headline bolder"
        >Sie möchten einen neuen curabox Antrag stellen?</span
      >
      <div class="download-flyout__content__subline">
        <span class="icon icon-checkmark-30px"></span>
        <span>Angaben zum ambulanten Dienst vorausgefüllt</span>
      </div>
      <BaseButton
        label="Antrag herunterladen"
        @click="download()"
        icon="icon-download-20px"
      ></BaseButton>
      <span class="download-flyout__content__text"
        >Antrag herunterladen, ausfüllen lassen und einreichen unter:</span
      >
      <div class="flex-row">
        <div class="icon icon-envelope-20px"></div>
        <a
          class="download-flyout__content__link bold"
          href="mailto:curabox@pflege.de"
          >curabox@pflege.de</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useStore } from '@/store/index';

const store = useStore();
const apiService = inject('apiService');

const isOpen = ref(false);
function download() {
  apiService.download('PdfCuraboxApplication', {}, 'curabox-Antrag');
}
</script>
