<template>
  <div
    :class="[
      'bulk-change',
      {
        'bulk-change--visible': patients.length,
        'bulk-change--visible--delayed': delayedVisible,
      },
    ]"
  >
    <div class="bulk-change__info">
      <b>{{ props.patients.length }}</b> von
      <b class="bold">{{ props.totalPatientsCount }}</b> curaboxen ausgewählt
    </div>
    <div
      class="option"
      v-for="(changeOption, key) in changeOptions"
      :class="{ 'option--disabled': !changeOption.isEnabled }"
      :key="key"
    >
      <div class="option__tooltip" v-if="!changeOption.isEnabled">
        In Ihrer Auswahl sind Kunden, für die diese Option nicht zur Verfügung
        steht.
      </div>
      <div
        :class="['option__label', `option__label--${key}`]"
        @click="toggleMenu(changeOption, key)"
      >
        <span
          :class="['icon', 'option__label__icon', changeOption.icon]"
        ></span>
        <span class="bold">{{ changeOption.label }}</span>
        <span
          :class="[
            'icon',
            `icon-chevron-${openChangeOption === key ? 'up' : 'down'}-20px`,
          ]"
        ></span>
      </div>

      <BulkChangeOptionMenu
        v-if="openChangeOption === key"
        :option-key="openChangeOption"
        :patients="patients"
        @close="closeMenu"
        @change="$emit('change')"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { useStore } from '@/store/index';
// import { isBoxApprovedForPatients } from '@/mixins/mixin';
import BulkChangeOptionMenu from './BulkChangeOptionMenu.vue';
import { getNextRegularBoxes } from '@/mixins/mixin.js';

const props = defineProps({
  patients: {
    type: Array,
  },
  totalPatientsCount: {
    type: Number,
  },
});

const store = useStore();

const timeout = ref(null);
const openChangeOption = ref(null);
const delayedVisible = ref(false);

// todo: out commented code is related to change from material to allergies. May be needed after MVP phase

const changeOptions = reactive({
  gloveSize: {
    label: 'Handschuhgröße anpassen',
    icon: 'icon-curabox-glove-20px',
    isEnabled: false,
  },
  // gloveMaterial: {
  //   label: 'Handschuhart anpassen',
  //   icon: 'icon-curabox-material-20px',
  //   isEnabled: false,
  // },
  pauseDelivery: {
    label: 'Lieferung pausieren',
    icon: 'icon-pause-20px',
    isEnabled: false,
  },
  // boxChange: {
  //   label: 'Boxzusammenstellung ändern',
  //   icon: 'icon-bulk-edit-20px',
  //   isEnabled: false,
  // },
});

function setOptionEnabled() {
  changeOptions.gloveSize.isEnabled = gloveOptionsEnabled();
  // changeOptions.gloveMaterial.isEnabled = gloveOptionsEnabled();
  changeOptions.pauseDelivery.isEnabled = pauseDeliveryOptionEnabled();
  // changeOptions.boxChange.isEnabled = boxChangeEnabled();
}

function gloveOptionsEnabled() {
  return props.patients.every((patient) => {
    const nextRegularBoxes = getNextRegularBoxes(patient);
    !!nextRegularBoxes[0]?.products.find(
      (p) => p.salesforceId === store.glovesSalesforceId && p.amount > 0,
    );
  });
}

function pauseDeliveryOptionEnabled() {
  return props.patients.every((patient) => !patient.paused);
}

// function boxChangeEnabled() {
//   return !!store.boxes.find((box) =>
//     isBoxApprovedForPatients(props.patients, box),
//   );
// }

function toggleMenu(changeOption, key) {
  if (openChangeOption.value === key) {
    closeMenu();
  } else {
    openChangeOption.value = changeOption.isEnabled ? key : null;
  }
}

function closeMenu() {
  openChangeOption.value = null;
}

watch(
  () => props.patients,
  (newVal, oldVal) => {
    setOptionEnabled();
    closeMenu();

    if ((!oldVal || !oldVal.length) && newVal && newVal.length) {
      timeout.value = setTimeout(() => {
        delayedVisible.value = true;
      }, 400);
    } else {
      clearTimeout(timeout.value);
      delayedVisible.value = newVal && newVal.length;
    }
  },
);
</script>
