<template>
  <div :class="el">
    <div :class="`${el}__headline`">{{ headline }}</div>
    <BaseClickBox
      v-if="['gloveSize', 'gloveMaterial'].includes(props.optionKey)"
      v-model="selectedValues[props.optionKey]"
      :options="
        store.gloveOptions[
          props.optionKey === 'gloveSize' ? 'sizes' : 'materials'
        ]
      "
    ></BaseClickBox>
    <div :class="`${el}__boxes`" v-if="props.optionKey === 'boxChange'">
      <div class="box" v-for="box in store.boxes" :key="box.id">
        <div
          :class="[
            'box__button',
            {
              'box__button--selected': selectedValues.box?.id === box.id,
              'box__button--disabled': !isBoxApprovedForPatients(
                props.patients,
                box,
              ),
            },
          ]"
          @click="selectedValues.box = box"
        >
          <span class="box__button__label bold">{{ box.name }}</span>
          <img
            class="box__button__product-image"
            v-for="(product, index) in box.products"
            :key="index"
            :src="`${product.image}.png`"
            :srcset="`${product.image}.png 1x, ${product.image}@2x.png 2x`"
            :alt="index"
            v-show="product.amount > 0"
          />
        </div>
        <div
          v-if="selectedValues.box?.id === box.id && boxHasGloves(box)"
          class="box__glove-options"
        >
          <BaseClickBox
            label="Neue Handschuhgröße wählen"
            v-model="selectedValues.gloveSize"
            :options="store.gloveOptions.sizes"
          ></BaseClickBox>
          <BaseClickBox
            label="Neue Handschuhart wählen"
            v-model="selectedValues.gloveMaterial"
            :options="store.gloveOptions.materials"
          ></BaseClickBox>
        </div>
      </div>
    </div>
    <BaseButton
      :class="`${el}__button`"
      @click="submitChange"
      :disabled="buttonDisabled"
      :loading="isLoading"
      :outline="true"
      :label="`für ${props.patients.length} Kunden übernehmen`"
    ></BaseButton>
  </div>
</template>

<script setup>
import { ref, computed, inject, reactive } from 'vue';
import { useStore } from '@/store/index';
import { isBoxApprovedForPatients } from '@/mixins/mixin';

const el = 'bulk-change-option-menu';
const props = defineProps({
  optionKey: {
    required: true,
  },
  patients: {
    type: Array,
    required: true,
  },
});

const store = useStore();
const apiService = inject('apiService');
const showHint = inject('showHint');
const emit = defineEmits(['close', 'change']);

const selectedValues = reactive({});
const isLoading = ref(false);

const headline = computed(() => {
  switch (props.optionKey) {
    case 'gloveSize':
      return 'Neue Handschuhgröße wählen';
    case 'gloveMaterial':
      return 'Neue Handschuhart wählen';
    case 'pauseDelivery':
      return 'Lieferung pausieren';
    case 'boxChange':
      return 'Neue Boxzusammenstellung wählen';
    default:
      return '';
  }
});

const buttonDisabled = computed(
  () =>
    props.optionKey !== 'pauseDelivery' &&
    (!selectedValues ||
      (props.optionKey === 'boxChange' &&
        boxHasGloves(selectedValues.box) &&
        (!selectedValues.gloveSize || !selectedValues.gloveMaterial))),
);

async function submitChange() {
  isLoading.value = true;

  const data = {
    patientIds: props.patients.map((p) => p.id),
    case: {
      key: props.optionKey,
      ...selectedValues,
    },
  };

  const response = await apiService.post('/cases/bulk-change', data);
  if (response.message === 'success') {
    await store.fetchReservedCasesCount();
    emit('change');
  }

  isLoading.value = false;
  showHint({
    id: response.message === 'success' ? 'bulkChange' : 'bulkChangeError',
    data: null,
  });
  emit('close');
}

function boxHasGloves(box) {
  return !!box?.products.find(
    (p) => p.salesforceId === store.glovesSalesforceId && p.amount > 0,
  );
}
</script>
