<template>
  <div
    :class="[
      'glove-options',
      'flex-column',
      {
        'glove-options--visible': isVisible,
        'glove-options--visible-overflow': isVisibleDelayed,
      },
    ]"
    v-if="store.gloveOptions"
  >
    <div class="glove-options_wrapper">
      <BaseClickBox
        class="glove-size"
        label="Welche Handschuhgröße haben Sie?"
        :options="store.gloveOptions.sizes"
        v-model="productSelection.gloveSize"
        :infoTooltip="{
          content: TOOLTIP_CONTENT,
        }"
        trackingLabel="boxzusammenstellung-handschuhgroeße"
      />
      <BaseClickBox
        :class="[
          'intolerances-check',
          { 'intolerances-check--visible': productSelection.gloveSize },
        ]"
        label="Hat die versicherte Person oder der Träger der Handschuhe eine Allergie / Unverträglichkeit gegen übliche Materialien: Latex, Vinyl, Nitril?"
        :options="intolerancesCheckOptions"
        trackingLabel="boxzusammenstellung-allergie"
        v-model="intolerancesOpen"
      />
    </div>
    <div class="glove-intolerances flex-column">
      <BaseClickBox
        :class="[
          'glove-intolerances__options',
          { 'glove-intolerances__options--visible': intolerancesOpen },
        ]"
        :options="store.gloveOptions.materials"
        v-model="productSelection.gloveIntolerances"
        :multi="true"
        label="Gegen welches Material liegt eine Allergie / Unverträglichkeit vor?"
        @update:modelValue="onIntolerancesInput"
      />
      <div
        :class="[
          'glove-intolerances__warning',
          { 'glove-intolerances__warning--visible': showIntolerancesWarning },
        ]"
      >
        <div class="glove-intolerances__warning__text">
          Wenn Sie gegen alle drei Materialien eine Allergie haben, können wir
          Ihnen keine Einmalhandschuhe anbieten.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { useStore } from '@/store/index';

const track = inject('track');
const store = useStore();

const emit = defineEmits(['validGloveSelection', 'gloveSelection']);

const props = defineProps({
  patient: {
    type: Object,
  },
  boxProduct: {
    type: Object,
  },
});

const gloveAmount = computed(() => {
  return props.boxProduct.amount;
});

const isVisible = ref(false);
const isVisibleDelayed = ref(false);
const intolerancesOpen = ref(false);
const showIntolerancesWarning = ref(false);
const productSelection = ref({});
let timeout = null;

const TOOLTIP_CONTENT =
  'Unsere empfohlene Handschuhgröße ist M. Diese passt dem Großteil der Menschen. ' +
  'Um die geeignete Handschuhgröße zu ermitteln, können Sie folgenden Leitfaden nutzen: ' +
  "<a href='https://curabox.de/curabox/handschuhe-messen.pdf' target='_blank'>Handschuhgröße ermitteln</a>";

productSelection.value.gloveSize =
  props.patient.case?.glove_size?.id ?? props.patient?.glove_size.id;

const intolerancesCheckOptions = [
  { name: 'Ja', value: true },
  { name: 'Nein', value: false },
];

const intolerances =
  props.patient.case?.glove_intolerances ?? props.patient.glove_intolerances;

intolerancesOpen.value = intolerances?.length > 0;

productSelection.value.gloveIntolerances = intolerances
  ? intolerances.map((i) => {
      return i.id;
    })
  : [];

changeVisibility();

emit('validGloveSelection', isValid());
emit('gloveSelection', productSelection);

function onIntolerancesInput(selectedIntolerances, action, selectedOption) {
  showIntolerancesWarning.value =
    productSelection.value.gloveIntolerances.length === 3;
  let trackingAction = action;

  if (showIntolerancesWarning.value) {
    productSelection.value.gloveIntolerances.pop();
    trackingAction = 'error';
  }

  const option = store.gloveOptions.materials.find(
    (material) => material.value === selectedOption,
  );
  track(
    `clickbox-${trackingAction}`,
    `boxzusammenstellung-allergie-${option.name}`,
  );
}

function changeVisibility() {
  window.clearTimeout(timeout);
  isVisible.value = !!gloveAmount.value;
  if (!isVisible.value) {
    hideIntolerances();
    productSelection.value.gloveSize = null;
    intolerancesOpen.value = false;
    isVisibleDelayed.value = false;
  } else {
    timeout = setTimeout(() => {
      isVisibleDelayed.value = true;
    }, 1000);
  }
}

function isValid() {
  if (props.boxProduct.amount < 1) {
    return true;
  } else if (props.boxProduct.amount > 0 && productSelection.value.gloveSize) {
    if (!productSelection.value.gloveIntolerancesOptionsOpen) {
      return true;
    } else if (
      productSelection.value.gloveIntolerancesOptionsOpen &&
      productSelection.value.gloveIntolerances.length > 0
    ) {
      return true;
    }
  }
  return false;
}

function hideIntolerances() {
  productSelection.value.gloveIntolerances = [];
  showIntolerancesWarning.value = false;
}

watch(gloveAmount, () => {
  changeVisibility();
});

watch(intolerancesOpen, () => {
  productSelection.value.gloveIntolerancesOptionsOpen = intolerancesOpen.value;
  if (!intolerancesOpen.value) {
    hideIntolerances();
  }
});

watch(
  () => productSelection,
  () => {
    emit('validGloveSelection', isValid());
    emit('gloveSelection', productSelection);
  },
  { deep: true },
);

watch(
  () => props.boxProduct,
  () => {
    emit('validGloveSelection', isValid());
  },
  { deep: true },
);
</script>
