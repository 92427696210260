<template>
  <div class="box-case triangle">
    <div class="box-case__header flex-row">
      <div class="flex-row">
        <span class="box-case__headline bold">Änderungen</span>
        <div
          :class="[
            'box-case__status',
            `box-case__status--${patient.case?.status}`,
          ]"
        >
          {{ getCaseStatus(patient) }}
        </div>
      </div>
      <IconLink
        label="Änderungen löschen"
        icon-name="delete"
        v-if="patient.case?.status === 'reserved'"
        @click="deleteCase()"
      />
    </div>

    <div class="row flex-row">
      <div
        v-if="
          patient.case.delivery_interval ||
          patient.case.termination_reason ||
          patient.case.paused ||
          patient.case.delivery_address
        "
        class="column column--delivery"
      >
        <div class="column__header bold">Lieferoptionen</div>
        <CaseDataDelivery :patient="patient" />
      </div>
      <div v-if="isGlovesChanged()" class="column column--gloves">
        <div class="column__header bold">Handschuhoptionen</div>
        <CaseDataGloves :patient="patient" />
      </div>
      <div v-if="patient.case?.products" class="column column--box">
        <div class="column__hint">* alt / <b>neu</b></div>
        <div class="column__header bold">Zusammenstellung curabox</div>
        <CaseDataBox :patient="patient" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { getCaseStatus } from '@/mixins/mixin';

const apiService = inject('apiService');
const emit = defineEmits(['deleteReservedCase']);

const props = defineProps({
  patient: {
    type: Object,
  },
  isLoading: {
    default: false,
  },
});

const deleteDisabled = ref(false);

// Needed to check if gloves have been changed in the new case
function isGlovesChanged() {
  return (
    props.patient.case?.glove_size?.name !== props.patient.glove_size ||
    props.patient.case?.glove_intolerances?.name !==
      props.patient.glove_intolerances
  );
}

async function deleteCase() {
  if (deleteDisabled.value || props.isLoading) {
    return;
  }

  deleteDisabled.value = true;
  const response = await apiService.delete(`/cases/${props.patient.case.id}`);
  if (response.message === 'success') {
    emit('updatePatient');
    deleteDisabled.value = false;
  }
}
</script>
