<template>
  <div class="authentication flex-row">
    <h3>Neuen Account erstellen</h3>
    <RegistrationForm class="authentication__form"></RegistrationForm>
  </div>
</template>

<script setup>

</script>
