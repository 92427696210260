import { computed, createApp, ref } from 'vue';
import { createPinia } from 'pinia';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import smoothscroll from 'smoothscroll-polyfill';
import KProgress from 'k-progress-v3';
import Keycloak from 'keycloak-js';

import 'core-js/stable';

import Tracking from 'shared-components/src/classes/Tracking';
import ApiService from './services/ApiService';

import App from './App.vue';
import router from './router';
import './styles/styles.scss';

/** ***********
 * Import Components
 **************/

/** ***
 * Shared Components
 ******/
import {
  BaseDate,
  BaseButton,
  BaseInput,
  BaseCheckbox,
  BaseRadio,
  BaseSelect,
  BaseAutocomplete,
  BaseClickBox,
  BaseTextArea,
  OptPhoneInput,
  InfoTooltip,
  TerminationHint,
  HintBox,
  AgbLayer,
  TerminationReasons,
} from 'shared-components';

/** ***
 * Professional Components
 ******/
// import BoxChangeHint from '@/components/BoxChangeHint.vue';
import BoxCase from '@/components/BoxCase.vue';
import BoxesPatientsMenu from '@/components/BoxesPatientsMenu.vue';
import BoxHeader from '@/components/BoxHeader.vue';
import BoxHistoryTable from '@/components/BoxHistoryTable.vue';
import BoxRow from '@/components/BoxRow.vue';
import BoxProgressbar from '@/components/BoxProgressbar.vue';
import BoxSelection from '@/components/BoxSelection.vue';
import CaseDataBox from '@/components/CaseDataBox.vue';
import CaseDataDelivery from '@/components/CaseDataDelivery.vue';
import CaseDataGloves from '@/components/CaseDataGloves.vue';
import CasesHint from '@/components/CasesHint.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Hint from '@/components/Hint.vue';
import Pagination from '@/components/Pagination.vue';
import DeliveryRow from '@/components/DeliveryRow.vue';
import DownloadFlyout from '@/components/DownloadFlyout.vue';
import IconLink from '@/components/IconLink.vue';
import LoginForm from '@/components/LoginForm.vue';
import PG51Row from '@/components/PG51Row.vue';
import ProductRow from '@/components/ProductRow.vue';
import ProfileEditForm from '@/components/ProfileEditForm.vue';
import SettingsUserEdit from '@/components/SettingsUserEdit.vue';
import SettingsUserTable from '@/components/SettingsUserTable.vue';
import ViewHeadline from '@/components/ViewHeadline.vue';
import GloveOptions from '@/components/GloveOptions.vue';
import AutoLogout from '@/components/AutoLogout.vue';

/** ***********
 * Constants
 **************/

const apiService = new ApiService();
const app = createApp(App);
const store = createPinia();

const showLogoutOverlay = ref(false);
const TOKEN_MIN_DURATION = 31;
/** ***********
 * Sentry
 **************/

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    logErrors: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0,
  });
}

/** ***********
 * Provides
 **************/

app.provide(
  'baseImagePath',
  import.meta.env.APP_ENV === 'development' ? './' : 'src',
);
app.provide('apiService', apiService);
app.provide(
  'showLogoutOverlay',
  computed(() => showLogoutOverlay.value),
);
app.provide('window', window);
app.provide('track', (event, label) => {
  Tracking.trackDatalayer({
    category: 'professional.curabox.de',
    event,
    label: `curabox-${label}`,
  });
});
smoothscroll.polyfill();

/** ***********
 * Component
 **************/

app.component('BaseAutocomplete', BaseAutocomplete);
app.component('BaseButton', BaseButton);
app.component('BaseCheckbox', BaseCheckbox);
app.component('BaseClickBox', BaseClickBox);
app.component('BaseDate', BaseDate);
app.component('BaseInput', BaseInput);
app.component('BaseRadio', BaseRadio);
app.component('BaseSelect', BaseSelect);
app.component('BaseTextArea', BaseTextArea);
app.component('BoxCase', BoxCase);
// app.component('BoxChangeHint', BoxChangeHint);
app.component('BoxesPatientsMenu', BoxesPatientsMenu);
app.component('BoxHeader', BoxHeader);
app.component('BoxHistoryTable', BoxHistoryTable);
app.component('BoxRow', BoxRow);
app.component('BoxProgressbar', BoxProgressbar);
app.component('BoxSelection', BoxSelection);
app.component('CaseDataBox', CaseDataBox);
app.component('CaseDataDelivery', CaseDataDelivery);
app.component('CaseDataGloves', CaseDataGloves);
app.component('CasesHint', CasesHint);
app.component('AgbLayer', AgbLayer);
app.component('Breadcrumb', Breadcrumb);
app.component('Hint', Hint);
app.component('HintBox', HintBox);
app.component('InfoTooltip', InfoTooltip);
app.component('Pagination', Pagination);
app.component('TerminationHint', TerminationHint);
app.component('DeliveryRow', DeliveryRow);
app.component('DownloadFlyout', DownloadFlyout);
app.component('IconLink', IconLink);
app.component('LoginForm', LoginForm);
app.component('OptPhoneInput', OptPhoneInput);
app.component('PG51Row', PG51Row);
app.component('ProductRow', ProductRow);
app.component('ProfileEditForm', ProfileEditForm);
app.component('SettingsUserEdit', SettingsUserEdit);
app.component('SettingsUserTable', SettingsUserTable);
app.component('ViewHeadline', ViewHeadline);
app.component('GloveOptions', GloveOptions);
app.component('AutoLogout', AutoLogout);

app.component('k-progress', KProgress);
app.component('TerminationReasons', TerminationReasons);

/** ***********
 * Uses
 **************/

app.use(store);
app.use(router);
store.use(() => ({ apiService }));

/** ***********
 * Init keycloak & mount app
 **************/

const initOptions = {
  url: 'https://login.fsb.p4e.io/auth/',
  realm: 'Bedarfsmeldung',
  clientId: 'curabox-professional',
  onLoad: 'login-required',
};

const keycloak = new Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      !import.meta.env.PROD ? console.info('Authenticated') : '';
      app.mount('#app');
      app.provide('keycloak', keycloak);
      localStorage.setItem('token', JSON.stringify(keycloak.token));
    }
    setInterval(() => {
      const tokenValidInSeconds = Math.round(
        keycloak.tokenParsed.exp +
          keycloak.timeSkew -
          new Date().getTime() / 1000,
      );
      showLogoutOverlay.value = tokenValidInSeconds < TOKEN_MIN_DURATION;
    }, 5000);
  })
  .catch(() => {
    !import.meta.env.PROD ? console.error('Authentication failed') : '';
  });
