<template>
  <div class="settings-table" v-if="store.user">
    <div class="loading-icon" v-if="isLoading">Daten werden geladen ...</div>
    <div class="settings-table-wrapper" v-if="!isLoading">
      <h1 class="outpatient_service_name">
        {{ store.outpatientService?.name }}
      </h1>
      <div class="outpatient_service_user">
        <span class="outpatient_service_user__headline">Nutzer</span>
        <span @click="router.push(`/settings/user/add`)">
          <span class="icon icon-add-20px"></span>
          <span>neuen anlegen</span>
        </span>
      </div>
      <table>
        <tr class="table__row">
          <th
            v-for="(header, index) in tableHeaders"
            :key="index"
            :class="[
              'table__header',
              `table__header--${sortDirection}`,
              { 'table__header--active': sortColumn === header.column },
            ]"
            @click="sort(header.column)"
          >
            {{ header.label }}
          </th>
        </tr>
        <tr
          class="table__row patient"
          v-for="u in store.outpatientService?.user"
          :key="u.id"
          v-show="!u.isAdmin"
        >
          <td>
            <span>{{ u.last_name }}, {{ u.first_name }}</span>
          </td>
          <td>{{ u.username }}</td>
          <td class="edit-user">
            <span @click="router.push(`/settings/user/edit/${u.id}`)">
              <span class="icon icon-edit-20px"></span>
              <span>Nutzerdaten anpassen</span>
            </span>
            <span @click="deleteUser(u)">
              <span class="icon icon-delete-20px"></span>
              <span>Löschen</span>
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/index';

const router = useRouter();
const store = useStore();

const apiService = inject('apiService');
const showHint = inject('showHint');

const isLoading = ref(true);
const tableHeaders = ref([
  {
    column: 'last_name',
    label: 'Nachname, Vorname',
  },
  {
    column: 'username',
    label: 'Benutzername',
  },
]);
const sortDirection = ref('asc');
const sortColumn = ref('last_name');

onMounted(() => {
  fetchOutpatientService();
});

async function fetchOutpatientService() {
  if (store.user.outpatient_services_id === undefined) {
    return;
  }
  const response = await apiService.get(
    `/outpatient-services/${store.user.outpatient_services_id}`,
  );
  store.outpatientService = response.data;
  isLoading.value = false;
}

function sort(_sortColumn) {
  if (sortColumn.value === _sortColumn) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  }
  sortColumn.value = _sortColumn;
  sortUsers(sortColumn.value, sortDirection.value);
}

function sortUsers(column, direction) {
  const user = store.outpatientService.user.sort(function (a, b) {
    const x = a[column].toLowerCase();
    const y = b[column].toLowerCase();
    return x.localeCompare(y);
  });
  return direction === 'asc' ? user : user.reverse();
}

async function deleteUser(user) {
  const response = await apiService.delete(`/users/${user.id}`);
  if (response.message === 'success') {
    await fetchOutpatientService();
    showHint({ id: 'deletedUser', data: user.username });
  } else {
    showHint({ id: 'deletedUserError', data: null });
  }
}
</script>
