<template>
  <div class="box-progressbar triangle">
    <BoxChangeHint
      :patient="props.patient"
      text="Die Änderung der Boxzusammenstellung wird für die Lieferung im MONTH wirksam"
    />
    <div class="box-progressbar__headline bolder">Boxwert</div>
    <div class="box-progressbar__progress flex-column">
      <div class="box-progressbar__progress__hint bold">
        {{ store.visualMaxPrice }}
        <span>€</span>
      </div>
      <KProgress
        :percent="getPercent()"
        bg-color="rgba(0, 0, 0, 0.1)"
        :color="props.totalPrice >= store.minPrice ? '#98c339' : '#00000099'"
        :show-text="false"
        :line-height="30"
        :class="{ 'k-progress--full': getPercent() >= 99 }"
      ></KProgress>
      <div class="box-progressbar__progress__arrow" :style="{ width: `${getArrowWidth()}` }"></div>

      <div v-if="props.totalPrice < store.minPrice" class="box-progressbar__progress__label bold">
        <span>Der Mindestbetrag ist noch nicht erreicht.</span>
      </div>
      <div v-else class="box-progressbar__progress__label bold flex-row">
        <div class="icon icon-checkmark-20px"></div>
        <span>Der Mindestbetrag ist erreicht.</span>
      </div>
    </div>
    <div
      v-if="props.patient.data.is_privately_insured"
      class="box-progressbar__insurance-hint flex-row"
    >
      <div class="icon icon-support-info-20px"></div>
      <div class="flex-column">
        <span class="bold">Bitte beachten</span>
        <span>
          Der Kunde ist privat versichert und geht in Vorleistung. Verpassen Sie nicht, ihn über die
          neuen Kosten zu informieren.
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index';

const store = useStore();

const props = defineProps({
  patient: {
    type: Object,
    required: true
  },
  totalPrice: {
    type: Number,
    required: true
  }
});

function getPercent() {
  return props.totalPrice < store.maxPrice ? props.totalPrice * (100 / store.maxPrice) : 100;
}
</script>
