<template>
  <label :class="el">
    <input
      :class="`${el}__input`"
      v-model="store.boxesTable.simpleFilterValue"
      placeholder="Name oder Kundennummer"
      @keyup.enter="filter"
    />
    <span
      :class="[`${el}__icon`, 'icon', 'icon-search-30px']"
      @click="filter"
    ></span>
  </label>
</template>

<script setup>
import { useStore } from '@/store/index';

const el = 'simple-filter';
const emit = defineEmits(['filter']);

const store = useStore();

function filter() {
  store.boxesTable.simpleFilterActive = true;
  emit('filter');
}
</script>
