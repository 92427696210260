<template>
  <div :class="el" v-if="isOverlayOpen">
    <div :class="`${el}__background`"></div>
    <div :class="`${el}__layer`" :id="`${el}__layer`">
      <span :class="`${el}__layer__headline`"
        >Sie werden gleich abgemeldet</span
      >
      <span :class="`${el}__layer__timer`"> {{ timer }}</span>
      <span :class="`${el}__layer__info`"
        >Zu Ihrer Sicherheit melden wir sie gleich automatisch ab. Sofern sie
        angemeldet bleiben wollen, klicken Sie auf “Angemeldet bleiben”</span
      >
      <BaseButton
        label="Angemeldet bleiben"
        @click="onStayLoggedInButtonClick"
      />
      <BaseButton
        label="Jetzt abmelden"
        :secondary="true"
        @click="onLogoutButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';

const el = 'auto-logout';
const TOKEN_MIN_DURATION = 31;

const showLogoutOverlay = inject('showLogoutOverlay');
const keycloak = inject('keycloak');
const isOverlayOpen = ref(false);
const logoutCountdown = ref(0);
const timer = computed(
  () => `00:${logoutCountdown.value < 10 ? '0' : ''}${logoutCountdown.value}`,
);

function onLogoutButtonClick() {
  keycloak.logout();
}

function onStayLoggedInButtonClick() {
  isOverlayOpen.value = false;
  keycloak
    .updateToken(TOKEN_MIN_DURATION)
    .then((refreshed) => {
      if (refreshed) {
        !import.meta.env.PROD
          ? console.info('Token refreshed: ' + refreshed)
          : '';
        localStorage.setItem('token', JSON.stringify(keycloak.token));
      }
    })
    .catch(() => {
      !import.meta.env.PROD ? console.error('Failed to refresh token') : '';
    });
}

function countdown() {
  setTimeout(() => {
    logoutCountdown.value -= 1;
  }, 1000);
}

watch(
  () => showLogoutOverlay,
  () => {
    isOverlayOpen.value = showLogoutOverlay.value;
    if (isOverlayOpen.value) {
      logoutCountdown.value = Math.round(
        keycloak.tokenParsed.exp +
          keycloak.timeSkew -
          new Date().getTime() / 1000,
      );
    }
  },
  { deep: true },
);

watch(logoutCountdown, () => {
  if (isOverlayOpen.value) {
    if (logoutCountdown.value > 0) {
      countdown();
    } else {
      keycloak.logout();
    }
  }
});
</script>
