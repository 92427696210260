import { defineStore } from 'pinia';

export const useStore = defineStore('main', {
  state: () => {
    return {
      isLoggedIn: false,
      user: {},
      outpatientService: {},
      products: [],
      boxes: [],
      minPrice: null,
      maxPrice: null,
      visualMaxPrice: null,
      gloveOptions: {},
      deliveryOptions: null,
      caseStatuses: [],
      totalPrice: 0,
      reservedCasesCount: 0,
      boxesTable: {
        sortColumn: 'lastname',
        sortDirection: 'asc',
        currentPage: 1,
        simpleFilterValue: '',
        extendedFilterValues: {
          gloveSizes: [],
          gloveMaterials: [],
          deliveryPaused: null,
          nextBoxEditable: null,
          caseStatuses: [],
        },
        simpleFilterActive: false,
        extendedFilterActive: false,
      },
      adminDataFetched: false,
    };
  },
  // Getters
  getters: {
    glovesSalesforceId(state) {
      const product = state.products?.find((p) => p.isGlove);
      return product?.salesforceId;
    },

    pg51SalesforceId(state) {
      const product = state.products.find((p) => p.isPG51);
      return product?.salesforceId;
    },

    product: (state) => (salesforceId) => {
      return state.products.find((p) => p.salesforceId === salesforceId);
    },
  },

  // actions
  actions: {
    async initialize(user) {
      this.setUser(user);
      this.fetchGloveOptions();
      this.fetchDeliveryOptions();
      this.fetchCaseStatuses();
      this.fetchAdminData();

      let ret = false;
      const response = await this.apiService.get(
        `/outpatient-services/${user.outpatient_services_id}`,
      );

      if (response) {
        ret = true;
        this.setIsLoggedIn(true);
        this.setOutpatientService(response.data);
      } else {
        localStorage.removeItem('user-token');
      }
      return ret;
    },

    async fetchGloveOptions() {
      const response = await this.apiService.get('/glove-options');
      this.setGloveOptions(response.data);
    },

    async fetchDeliveryOptions() {
      const response = await this.apiService.get('/delivery-options');
      this.setDeliveryOptions(response.data);
    },

    async fetchCaseStatuses() {
      const caseStatuses = [];
      const response = await this.apiService.get('/case-statuses');

      response.data.forEach((status) => {
        caseStatuses.push({
          name: status.slug,
          id: status.id,
        });
      });
      this.setCaseStatuses(caseStatuses);
    },

    async fetchAdminData() {
      const products = await (
        await fetch(
          `${import.meta.env.VITE_APP_CURABOX_ADMIN_URL}/api/data/products`,
        )
      ).json();

      const boxes = await (
        await fetch(
          `${import.meta.env.VITE_APP_CURABOX_ADMIN_URL}/api/data/boxes`,
        )
      ).json();

      const prices = await (
        await fetch(
          `${import.meta.env.VITE_APP_CURABOX_ADMIN_URL}/api/data/prices`,
        )
      ).json();

      products.forEach((product) => {
        product.size = `${product.packageSize} ${product.unit}`;
      });

      boxes.forEach((box) => {
        products.forEach((product) => {
          const boxProduct = box.products.find(
            (p) => parseInt(p.id, 10) === product.id,
          );
          if (boxProduct) {
            boxProduct.amount = parseInt(boxProduct.quantity, 10);
            boxProduct.image = product.image;
            boxProduct.name = product.name;
            boxProduct.salesforceId = product.salesforceId;
            boxProduct.size = product.size;
            delete boxProduct.quantity;
          } else if (!product.isPG51) {
            box.products.push({
              image: product.image,
              salesforceId: product.salesforceId,
              amount: 0,
            });
          }
        });
      });

      this.setProducts(products);
      this.setBoxes(boxes);
      this.setPrices(prices);
      this.adminDataFetched = true;
    },

    async fetchReservedCasesCount() {
      const response = await this.apiService.get('/cases/reserved-count');
      this.setReservedCasesCount(response.data);
    },

    // Mutations are now actions, using 'this' instead of 'state'

    setPrices(prices) {
      this.minPrice = prices.minPrice;
      this.maxPrice = prices.maxPrice;
      this.visualMaxPrice = prices.visualMaxPrice;
    },
    setUser(user) {
      this.user = user;
    },
    setOutpatientService(outpatientService) {
      this.outpatientService = outpatientService;
    },
    setIsLoggedIn(isLoggedIn) {
      this.isLoggedIn = isLoggedIn;
    },
    setProducts(products) {
      this.products = products;
    },
    setBoxes(boxes) {
      this.boxes = boxes;
    },
    setGloveOptions(gloveOptions) {
      this.gloveOptions = gloveOptions;
    },
    setDeliveryOptions(deliveryOptions) {
      this.deliveryOptions = deliveryOptions;
    },
    setCaseStatuses(caseStatuses) {
      this.caseStatuses = caseStatuses;
    },
    setReservedCasesCount(count) {
      this.reservedCasesCount = count;
    },
  },
});
