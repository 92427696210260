<template>
  <div class="authentication__form--login flex-row">
    <div class="authentication__form--login__logo flex-column">
      <img
        src="../assets/logo-curabox.png"
        srcset="../assets/logo-curabox.png 1x, ../assets/logo-curabox@2x.png 2x"
        alt="curabox"
      />
      <div class="authentication__form--login__logo__subline">
        Verwalten Sie die Produkte<br />
        Ändern Sie die Lieferbedingungen<br />
        Verfolgen Sie Ihre nächste Lieferung an einem Ort
      </div>
    </div>
    <div class="authentication__form--login__inputs">
      <component
        v-for="(data, key) in formData"
        v-bind="{ ...data }"
        v-model="data.value"
        @keyup.enter="login()"
        @input="resetErrors(formData)"
        :name="key"
        :key="key"
        :is="data.component"
        :hide-success-icon="true"
      />
      <BaseButton
        class="login_btn"
        @click="login()"
        label="Anmelden"
        :loading="isLoading"
      ></BaseButton>
      <BaseButton
        class="register_btn"
        @click="goToRegistration()"
        label="Konto anfragen"
        :outline="true"
        v-if="false"
      >
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, reactive, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { setErrors, resetErrors } from '@/mixins/mixin';
import { useStore } from '@/store/index';
import formDataJson from '@/schemas/loginDataSchema.json';

const store = useStore();
const router = useRouter();
const apiService = inject('apiService');
const showHint = inject('showHint');

const isLoading = ref(false);
const formData = reactive(formDataJson);

onUnmounted(() => {
  formData.email_username.value = '';
  formData.password.value = '';
});

async function login() {
  resetErrors(formData);
  isLoading.value = true;
  const data = {
    password: formData.password.value,
  };

  data[formData.email_username.value.includes('@') ? 'email' : 'username'] =
    formData.email_username.value;

  const response = await apiService.login(data);

  if (response.token) {
    await store.initialize(response.user);
    if (store.user?.active === 1) await router.push('/boxes');
  } else if (response.errors) {
    setErrors(response.errors, formData);
    showHint({ id: 'loginError', data: null });
  }
  isLoading.value = false;
}

function goToRegistration() {
  router.push('/registrierung');
}
</script>
