<template>
  <div class="pagination flex-row">
    <span
      class="icon icon-chevron-left-30px"
      :class="{ hidden: currentPage === 1 }"
      @click="emit('goToPage', currentPage - 1)"
    ></span>
    <span
      :class="[
        'bold',
        'pagination__number',
        { 'pagination__number--active': page === props.currentPage },
      ]"
      v-for="page in pageCount"
      :key="page"
      v-show="isPageVisible(page)"
      @click="emit('goToPage', page)"
    >
      {{ page }}
    </span>
    <span
      class="icon icon-chevron-right-30px"
      :class="{ hidden: currentPage === pageCount }"
      @click="emit('goToPage', currentPage + 1)"
    ></span>
  </div>
</template>

<script setup>
const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  pageCount: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['goToPage']);

function isPageVisible(page) {
  if (props.currentPage === props.pageCount) {
    return page >= props.currentPage - 4;
  }
  if (props.currentPage === props.pageCount - 1) {
    return page >= props.currentPage - 3 || page === props.pageCount;
  }
  if (props.currentPage === 1) {
    return page <= props.currentPage + 4;
  }
  if (props.currentPage === 2) {
    return page <= props.currentPage + 3 || page === 1;
  }

  return page <= props.currentPage + 2 && page >= props.currentPage - 2;
}
</script>
