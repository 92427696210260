<template>
  <div class="case-data case-data--box flex-row">
    <div
      class="case-data--box__product"
      v-for="(product, index) in products"
      :key="index"
      v-show="products.length > 0"
    >
      <ProductImage :product="product" :small="true" />
      <div class="case-data--box__product__amount">
        <span class="case-data--box__product__amount--old">{{
          product.oldAmount
        }}</span>
        <span> / </span>
        <span class="case-data--box__product__amount--new bold">{{
          product.newAmount
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from '@/store/index';
import { getNextRegularBoxes } from '@/mixins/mixin.js';
import ProductImage from '@/components/ProductImage.vue';

const store = useStore();

const props = defineProps({
  patient: {
    type: Object,
    required: true,
  },
});

const products = ref([]);

onMounted(() => {
  getProductAmount();
});

function getProductAmount() {
  props.patient.case.products?.forEach((p) => {
    const storeProduct = store.products.find(
      (r) => r.salesforceId === p.salesforceId,
    );

    const nextRegularBoxes = getNextRegularBoxes(props.patient);
    const orderProduct = nextRegularBoxes[0]?.products.find(
      (c) => c.salesforceId === p.salesforceId,
    );

    products.value.push({
      name: storeProduct.name,
      salesforceId: p.salesforceId,
      newAmount: p.amount,
      oldAmount: orderProduct?.amount || 0,
      image: storeProduct.image,
      size: storeProduct.size,
    });
  });
}
</script>
